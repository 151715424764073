import { withDependencies } from '@wix/thunderbolt-ioc'
import { IPageDidMountHandler } from '@wix/thunderbolt-symbols'
import { IBackgroundScrub } from './types'
import { BackgroundScrubSymbol } from './symbols'

const backgroundScrubInitFactory = (backgroundScrub: IBackgroundScrub): IPageDidMountHandler => {
	return {
		async pageDidMount() {
			await backgroundScrub.init()
		},
	}
}

export const BackgroundScrubInit = withDependencies([BackgroundScrubSymbol], backgroundScrubInitFactory)
