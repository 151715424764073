import fastdom from 'fastdom'
import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import { isSafari, isEdge, isIE, isFirefox, getSafariMajorVersion } from '@wix/thunderbolt-commons'
import { BrowserWindow, BrowserWindowSymbol, PageFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import {
	BackgroundScrubPageConfig,
	IBackgroundScrub,
	ScrubMeasurements,
	SequenceInstancesFactory,
	SequenceInstancesStore,
} from './types'
import { name } from './symbols'
import { Animations, AnimatorManager, IAnimations } from 'feature-animations'

const backgroundScrubFactory = (
	pageFeatureConfig: BackgroundScrubPageConfig,
	window: BrowserWindow,
	animationManager: IAnimations
): IBackgroundScrub => {
	const measurements: ScrubMeasurements = {
		scrollY: 0,
		viewPortHeight: 0,
		wixAdsHeight: 0,
		siteHeight: 0,
		components: {},
	}
	const sequenceFactories: SequenceInstancesFactory = {}
	const sequenceInstances: SequenceInstancesStore = {}
	const animateRevealScrubAction = isIE(window!) || isEdge(window!) || isFirefox(window!)
	const animateParallaxScrubAction = isEdge(window!)
	const preserve3DParallaxScrubAction = !(
		isFirefox(window!) ||
		(isSafari(window!) && getSafariMajorVersion(window!) >= 9)
	)

	const getSiteHeight = (): number => {
		const masterPage = window!.document.getElementById('masterPage')
		const wixAds = window!.document.getElementById('WIX_ADS')

		let wixAdsOffsetHeight = 0
		if (wixAds) {
			wixAdsOffsetHeight = wixAds.offsetHeight
		}
		measurements.wixAdsHeight = wixAdsOffsetHeight
		return masterPage!.offsetHeight - wixAdsOffsetHeight
	}

	const getMaxTravel = ({
		animationManagerInstance,
		animationName,
		compMeasures,
		siteHeight,
		viewPortHeight,
	}: {
		animationManagerInstance: AnimatorManager
		animationName: string
		compMeasures: { height: number; top: number }
		siteHeight: number
		viewPortHeight: number
	}): number => {
		const maxTravelGetter = animationManagerInstance.getAnimationProperties(animationName).getMaxTravel
		return maxTravelGetter ? maxTravelGetter(compMeasures, viewPortHeight, siteHeight) : viewPortHeight
	}

	const initScrubMeasurements = (animationManagerInstance: AnimatorManager) => {
		measurements.scrollY = window!.pageYOffset
		const siteHeight = getSiteHeight()
		measurements.siteHeight = siteHeight
		// TODO: resize consider measuring using resize observer to get correct value on every browser resize
		const viewPortHeight = window!.document.documentElement.clientHeight
		measurements.viewPortHeight = viewPortHeight

		Object.entries(pageFeatureConfig.scrubSequencesParams).forEach(([_, sequenceParams]) => {
			const { compId, animationName } = sequenceParams
			const component = window!.document.getElementById(compId)
			if (component) {
				const height = component.offsetHeight
				const absoluteTop =
					measurements.scrollY + component.getBoundingClientRect().top - measurements.wixAdsHeight
				const maxTravel = getMaxTravel({
					animationManagerInstance,
					animationName,
					compMeasures: {
						height,
						top: absoluteTop,
					},
					siteHeight,
					viewPortHeight,
				})
				const offset = animationName === 'SiteBackgroundParallax' ? 0 : viewPortHeight - absoluteTop
				measurements.components[compId] = {
					height,
					maxTravel,
					offset,
				}
			}
		})
	}

	const createScrubSequenceInstances = (animationManagerInstance: AnimatorManager) => {
		Object.entries(pageFeatureConfig.scrubSequencesParams).forEach(([sequenceId, sequenceParams]) => {
			const {
				animationName,
				compId,
				targetElementSelector,
				extraAnimationSelectors,
				duration,
				speedFactor,
				delay,
			} = sequenceParams
			const { viewPortHeight, components } = measurements
			const { height } = components[compId]
			sequenceFactories[sequenceId] = () =>
				animationManagerInstance.runAnimation({
					name: animationName,
					targetId: targetElementSelector,
					duration,
					delay,
					animationSelectors: extraAnimationSelectors as Record<string, string>,
					params: {
						viewPortHeight,
						browserFlags: {
							animateRevealScrubAction,
							animateParallaxScrubAction,
							preserve3DParallaxScrubAction,
						},
						componentHeight: height,
						suppressReactRendering: false,
						forgetSequenceOnComplete: false,
						speedFactor,
						paused: true,
					},
				})
		})
	}

	const scrubProgress = () => {
		const { scrollY, components } = measurements
		Object.entries(pageFeatureConfig.scrubSequencesParams).forEach(([sequenceId, sequenceParams]) => {
			const { compId } = sequenceParams
			const { maxTravel, offset } = components[compId]
			if (!sequenceInstances[sequenceId]) {
				sequenceInstances[sequenceId] = sequenceFactories[sequenceId]()
			}

			const pos = Math.max(0, scrollY) + offset
			const progress = maxTravel ? pos / maxTravel : 0
			sequenceInstances[sequenceId].progress(progress)
		})
	}

	const scrubTick = () => {
		fastdom.measure(() => {
			measurements.scrollY = window!.pageYOffset
			fastdom.mutate(scrubProgress)
		})
	}

	return {
		async init() {
			const animationsManagerInstance = animationManager && (await animationManager.getInstance())
			if (!animationsManagerInstance) {
				return
			}

			fastdom.measure(() => {
				initScrubMeasurements(animationsManagerInstance)

				fastdom.mutate(() => {
					createScrubSequenceInstances(animationsManagerInstance)
				})
			})

			// Invoke scrubbers for the first time with scroll 0 to get correct base dom state
			scrubTick()

			window!.addEventListener('scroll', scrubTick)
		},
		async destroy() {
			const animationsManagerInstance = animationManager && (await animationManager.getInstance())
			if (!animationsManagerInstance) {
				return
			}

			Object.values(sequenceInstances).forEach((sequenceInstance) =>
				animationsManagerInstance.kill(sequenceInstance)
			)
			window!.removeEventListener('scroll', scrubTick)
		},
	}
}

export const BackgroundScrub = withDependencies(
	[named(PageFeatureConfigSymbol, name), BrowserWindowSymbol, optional(Animations)],
	backgroundScrubFactory
)
